import { isNumber } from "@mcwd/typescript-type-guards";
import type {} from "jquery";

// The functions for the cube 
export function getUserInputData() {
  var [weeklyDocsAndTrainingRecords, weeklyHours, qaStaff, hourlyRate] = getRoiInputValues();
  var [firstYearSavings, secondYearSavings] = getCalculatedValues();
  if(!isNumber(firstYearSavings)) {
    throw new Error('Error getting First Year Savings');
  }
  if(!isNumber(secondYearSavings)) {
    throw new Error('Error getting Second Year Savings');
  }

  // Format with commas
  var firstYearSavingsFormatted = firstYearSavings.toLocaleString("en-us", {
      minimumFractionDigits: 0
  });
  var secondYearSavingsFormatted = secondYearSavings.toLocaleString("en-us", {
      minimumFractionDigits: 0
  });

  var roiData = {
    userInputs: [
        { 
          label: "Weekly Documents and Training Records", 
          value: weeklyDocsAndTrainingRecords 
        },{ 
          label: "Weekly Hours Managing", 
          value: weeklyHours 
        },{ 
          label: "Quality Staff",   
          value: qaStaff 
        },{ 
          label: "Quality Staff Hourly Rate",  
          value: hourlyRate 
        }
    ],
    teaserStats: [
        { 
          label: "First Year Savings", 
          value: firstYearSavingsFormatted, 
          rowClass: "unlocked stats-dollar-before" 
        }
    ],
    gatedStats: [
        { 
          label: "Second Year Savings", 
          value: secondYearSavingsFormatted, 
          rowClass: "yeartwo-savings-row stats-dollar-before" 
        }
    ]
  } as const

  return roiData;
}

export function cleanupNumberFromText(textNum) {
  var isNumberRegex = /^[0-9]+(\.[0-9]+)?$/;

  var val = (textNum || "").trim();
  val = val.replace(/,/g, ""); // Remove commas
  var isValid = val.length > 0 && isNumberRegex.test(val);
  return isValid ? parseFloat(val) : null;
}

function getRoiInputValues(): Array<number | null> {
  return $('.roi-number').toArray().map( el => {
      return cleanupNumberFromText($(el).text());
  });
}

function getCalculatedValues(): [number, number] | [null, null] {
  var [_weeklyDocsAndTrainingRecords, weeklyHours, _qaStaff, hourlyRate] = getRoiInputValues();

  if (weeklyHours !== null && hourlyRate !== null) {
      let firstYearSavings: number;
      let secondYearSavings: number;
      // Calculate values
      var weeksInYear = 49;
      var amountSpent = (weeklyHours * hourlyRate) * weeksInYear;
      firstYearSavings = Math.round(amountSpent * 0.10);
      secondYearSavings = Math.round((amountSpent * 0.2) * 1.05);
      return [firstYearSavings, secondYearSavings];
  }
  return [null, null];
}