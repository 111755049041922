import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-lg-row justify-content-between" }
const _hoisted_2 = { class: "stats-wrpr dark" }
const _hoisted_3 = { class: "form-stats" }
const _hoisted_4 = { class: "user-inputs" }
const _hoisted_5 = { class: "stat-row d-flex align-items-center justify-content-between" }
const _hoisted_6 = { class: "stat-name" }
const _hoisted_7 = ["data-roi"]
const _hoisted_8 = { class: "teaser-stats" }
const _hoisted_9 = { class: "stat-name" }
const _hoisted_10 = {
  class: "stat-value",
  "data-roi": "5"
}
const _hoisted_11 = { class: "prospect-form" }
const _hoisted_12 = { id: "eclipse-load" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["eclipse-form-root mc-roi-form neutral", {success: $setup.showUnlockedView}]),
    "data-uniq-form-id": ""
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode(" Convert this into the RoiFormBuilder build "),
        _createElementVNode("section", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.roiData.userInputs, (input, index) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(input.label), 1 /* TEXT */),
                _createElementVNode("p", {
                  class: "stat-value",
                  "data-roi": index
                }, _toDisplayString(input.value), 9 /* TEXT, PROPS */, _hoisted_7)
              ]))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.roiData.teaserStats, (input) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["stat-row d-flex align-items-center justify-content-between", input.rowClass])
              }, [
                _createElementVNode("p", _hoisted_9, _toDisplayString(input.label), 1 /* TEXT */),
                _createElementVNode("p", _hoisted_10, _toDisplayString(input.value), 1 /* TEXT */)
              ], 2 /* CLASS */))
            }), 256 /* UNKEYED_FRAGMENT */))
          ])
        ])
      ]),
      _createCommentVNode(" This is where the success loads "),
      ($setup.showUnlockedView)
        ? (_openBlock(), _createBlock($setup["roiSuccess"], {
            key: 0,
            "gated-stats": $setup.roiData.gatedStats
          }, null, 8 /* PROPS */, ["gated-stats"]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createCommentVNode(" Eclipse Form Loads Here "),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _renderSlot(_ctx.$slots, "form")
              ])
            ])
          ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
    ])
  ], 2 /* CLASS */))
}