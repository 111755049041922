<script setup lang="ts">
import type { ContactFormSettingsKey } from "../../form-settings/form-experience/form-settings-keys.js";
import { UserMarketoInputName, type MarketoVals } from "../../types/definitions/inputs/marketo-input-names-and-values.js";
import { MarketoAllInputMeta } from "../../types/definitions/inputs/marketo-input-meta.js";
import { DropdownInputName, getFormDropdownOptionKeys } from "../../types/definitions/inputs/select-box-fields.js";
import type { FormWidgetData } from "../../../widgetDataTs.js";
import { useValidations } from "../../composables/use-input-validation.js";
import {
  useFormServices,
  useInputsMetaData,
  useInputState,
  useFormSubmit,
  type InputStateOption,
  useEclipseHtmlFormElement
} from "../../composables/use-form-services.js";
import { useFormUserInputValues } from "../../composables/use-form-user-input-values.js";

import eclipseInput from "../eclipse-input.vue";
import emailOptInCheckbox from "../email-opt-in-checkbox.vue";
import submitButton from '../form-experience/submit-button.vue';
import { useTranslation } from "i18next-vue";
import { TranslationNamespaceEnum } from "../../locale/settings/translation-namespaces.js";
import type { SubmitButtonTranslationKey } from "../../locale/translation-keys/submit-button-text-keys.js";
import { ref, watch } from "vue";
import { isNil, type Nil } from "@mcwd/typescript-type-guards";
import loaderDark from "../form-experience/loader-icon-dark.vue";
import formLoadError from "./form-load-error.vue";
import { useFormLogger } from "../../composables/use-form-logger.js";
import { GetComputedProperties, type CustomData } from "../../composables/use-custom-data-values.js";


const props = defineProps<{
  formSettingsKey: ContactFormSettingsKey;
  widgetData: FormWidgetData<"ContactForm">;
  customData?: CustomData;
  isInModal?: boolean;
}>();

const { logger } = useFormLogger();

const { ctaButtonText, disableFormHeading } = GetComputedProperties(props.customData);

const { FormSettings, FormClasses, FormState } = useFormServices({ callerLabel: "roi-form-base.vue" });
const{ validateGenericBusinessEmail, validatePhoneNumber } = useValidations();


if (!("FullGate" in FormSettings.enabledFields)) {
  throw new Error(`Full Gate does not exist for formExperience '${FormSettings.formSettingsKey}'`);
}
const enabledFields = FormSettings.enabledFields.FullGate satisfies readonly string[];
type EnabledField = typeof enabledFields[number];

const { FormInputsMeta } = useInputsMetaData({ callerLabel: "roi-form-base.vue" }) as { FormInputsMeta: Pick<MarketoAllInputMeta, EnabledField> };

function getEnabledUserFields() {
  return enabledFields.filter(f => (UserMarketoInputName as readonly string[]).includes(f)) as Extract<EnabledField, UserMarketoInputName>[];
}

// Currently only handles marketo field names
const anyEnabledUserField = getEnabledUserFields();
type AnyEnabledUserField = typeof anyEnabledUserField[number];

function isFieldEnabled(field: UserMarketoInputName) {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  return anyEnabledUserField.includes(field as AnyEnabledUserField);
}
type SelectFieldName = Extract<EnabledField, DropdownInputName>;
const FormOptions = getFormDropdownOptionKeys(enabledFields.filter(f => (DropdownInputName as readonly string[]).includes(f)) as SelectFieldName[]);

type UserMarketoValues = { [Key in AnyEnabledUserField]: Required<MarketoVals>[Key] };

const { userInputValues, setFormUserInputValuesFromSettings } = useFormUserInputValues<AnyEnabledUserField, UserMarketoValues>({ callerLabel: "roi form" });
setFormUserInputValuesFromSettings();

const { setInputStates, inputStates } = useInputState<AnyEnabledUserField>({ callerLabel: "roi-form-base.vue" });
setInputStates(userInputValues, {
  "FirstName": { required: true },
  "LastName": { required: true },
  "Email": {
    required: true,
    validator: validateGenericBusinessEmail,
  },
  "Company": { required: true },
  "Country": { required: true },
  "Phone": {
    required: true,
    validator: validatePhoneNumber,
  },
  "Tier__c": { required: true },
  "Industry": { required: true },
  "Email_Opt_In__c": { required: false },
  ...(isFieldEnabled('Webform_Comments_recent_value__c') ? {
    "Webform_Comments_recent_value__c": { required: false },
  } : {}),
  ...(isFieldEnabled('Latest_Solution_Interest__c') ? {
    "Latest_Solution_Interest__c": { required: true },
  } : {}),
} as Record<AnyEnabledUserField, InputStateOption>);

const { submitForm } = useFormSubmit({ callerLabel: "roi-form-base.vue" });
const { t } = useTranslation();
const buttonTextMapKey: SubmitButtonTranslationKey<typeof FormSettings.formSettingsKey> = `${FormSettings.formSettingsKey}-Submit-Button`;

const formError = window.formLoadError;

const { setEclipseHtmlFormElement } = useEclipseHtmlFormElement({ callerLabel: "contact-form-base.vue" });
const formElRef = ref<HTMLFormElement | Nil>(null);

watch(() => formElRef.value, (formEl) => {
  if (!isNil(formEl)) {
    logger.debug({ formEl });
    if (!isNil(formEl)) {
      setEclipseHtmlFormElement(formEl);
    }
  }
});
</script>

<template>
  <template v-if="formError">
    <formLoadError />
  </template>

  <template v-else-if="FormState === 'Loading' || FormState === 'Setup'">
    <!-- Loader for the form -->
    <div class="form-loading">
      <loaderDark />
      <p> Loading Content</p>
    </div>
  </template>

  <template v-else-if="FormState === 'RegularForm'">
    <slot name="heading">
      <template v-if="!disableFormHeading">
        <h5 class="supporting-copy">
          {{ t("qx-roi-Heading", { ns: TranslationNamespaceEnum.formHeadingText }) }}
        </h5>
      </template>
    </slot>

    <form ref="formElRef" class="eclipse-form" :class="FormClasses.marketoFormClass" v-bind="$attrs"
      data-testid="form-root">
      <eclipseInput v-model="userInputValues.FirstName" :input-state="inputStates.FirstName"
        :form-input-meta="FormInputsMeta.FirstName" />

      <eclipseInput v-model="userInputValues.LastName" :input-state="inputStates.LastName"
        :form-input-meta="FormInputsMeta.LastName" />

      <eclipseInput v-model="userInputValues.Email" :input-state="inputStates.Email"
        :form-input-meta="FormInputsMeta.Email" />
      <slot name="middleHeading">
      </slot>
      <eclipseInput v-model="userInputValues.Company" :input-state="inputStates.Company"
        :form-input-meta="FormInputsMeta.Company" />

      <eclipseInput v-model="userInputValues.Country" :input-state="inputStates.Country"
        :form-input-meta="FormInputsMeta.Country" :dropdown-option-keys="FormOptions.Country"
        dropdown-translations-namespace="countries" />

      <eclipseInput v-model="userInputValues.Phone" :input-state="inputStates.Phone"
        :form-input-meta="FormInputsMeta.Phone" />

      <eclipseInput v-model="userInputValues.Tier__c" :input-state="inputStates.Tier__c"
        :form-input-meta="FormInputsMeta.Tier__c" :dropdown-option-keys="FormOptions.Tier__c"
        dropdown-translations-namespace="company-sizes" />

      <eclipseInput v-model="userInputValues.Industry" :input-state="inputStates.Industry"
        :form-input-meta="FormInputsMeta.Industry" :dropdown-option-keys="FormOptions.Industry"
        dropdown-translations-namespace="industries" />

      <emailOptInCheckbox v-model="userInputValues.Email_Opt_In__c" :form-input-meta="FormInputsMeta.Email_Opt_In__c"
        :input-state="inputStates.Email_Opt_In__c" :marketo-input-name="FormInputsMeta.Email_Opt_In__c.marketoName" />

      <!-- Eclipse Submit Button -->

      <submitButton @submit-form="submitForm()">
        <template v-if="ctaButtonText">
          {{ ctaButtonText }}
        </template>
        <template v-else>
          {{ t(buttonTextMapKey, { ns: TranslationNamespaceEnum.submitButtonText }) }}
        </template>
      </submitButton>
    </form>
  </template>
</template>