<script lang="ts" setup>
  import { getUserInputData } from './qx-roi-calculations.js';

  const roiData = getUserInputData();
</script>

<template>
  <div class="success-wrpr after-complete">
    <div class="img-wrapper d-flex flex-column align-items-center justify-content-center">
      <img src="https://static.mastercontrol.com/assets/persist/images/mcui-resources-sent.png">
      <div class="form-extra-stats" v-for="input in roiData.gatedStats">
        <p>Your request has been received and you will be contacted within 48 hours. If you'd like to speak to a specialist immediately, please call +1-866-747-8767.</p>
        <div class="gated-stats">
          <div class="stat-row d-flex align-items-center justify-content-between" :class="input.rowClass">
            <p class="stat-name">{{ input.label }}</p>
            <p class="stat-value">{{ input.value }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>