import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "eclipse-modal-content",
  class: "eclipseBodyContent"
}
const _hoisted_2 = { class: "supporting-copy" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" If in modal "),
    ($setup.isInModal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode($setup["modalBody"], null, {
            form: _withCtx(() => [
              _createVNode($setup["roiForm"], {
                "form-settings-key": $props.formSettingsKey,
                "widget-data": $props.widgetData,
                "custom-data": $props.customData
              }, _createSlots({ _: 2 /* DYNAMIC */ }, [
                (!$setup.isNil($setup.customFormHeading) && $setup.customFormHeading != '')
                  ? {
                      name: "heading",
                      fn: _withCtx(() => [
                        _createElementVNode("h5", _hoisted_2, _toDisplayString($setup.customFormHeading), 1 /* TEXT */)
                      ]),
                      key: "0"
                    }
                  : undefined
              ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["form-settings-key", "widget-data", "custom-data"])
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createCommentVNode(" Not in modal "),
          _createVNode($setup["roiForm"], {
            "form-settings-key": $props.formSettingsKey,
            "widget-data": $props.widgetData,
            "custom-data": $props.customData
          }, null, 8 /* PROPS */, ["form-settings-key", "widget-data", "custom-data"])
        ], 64 /* STABLE_FRAGMENT */))
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}